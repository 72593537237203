import queryString from 'query-string';
import { api } from 'src/api';
import { IResponseItem } from './restApi';

export enum PhraseType {
  Approved = 'approved',
  ForbiddenComments = 'comments-forbidden',
  ForbiddenAlerts = 'alerts-forbidden',
  ProfanityComments = 'comments-profane',
  ForceManualComments = 'comments-force-manual',
  ForceManualAlerts = 'alerts-force-manual',
  Risky = 'risky',
}

export enum PhraseLanguage {
  English = 'english',
  Spanish = 'spanish',
}

export interface INewPhrase {
  phrase: string;
  word_type: PhraseType;
  language: PhraseLanguage;
}

export interface IPhrase extends INewPhrase {
  id: string;
  created_at: number;
}

export interface IPhraseList {
  words: IPhrase[];
}

const path = 'words';

export class PhrasesService {
  public static async getPhrases(
    phraseTypes: PhraseType[] = [],
    phraseLanguages: PhraseLanguage[] = [],
  ) {
    const urlParams = queryString.stringify({
      language: phraseLanguages,
      word_type: phraseTypes,
    });

    const response = await api
      .get(`${path}?${urlParams}`)
      .json<IResponseItem<IPhraseList>>();

    return response.data.words;
  }

  public static async addPhrases(
    phrases: string[],
    phrasesType: PhraseType,
    phrasesLang: PhraseLanguage,
  ) {
    const requestPayload = phrases.map<INewPhrase>((phrase: string) => ({
      language: phrasesLang,
      phrase,
      word_type: phrasesType,
    }));

    const response = await api
      .post(path, { json: requestPayload })
      .json<IResponseItem<IPhraseList>>();

    return response.data.words;
  }

  public static deletePhrases(ids: string[]) {
    return api.delete(path, { json: { ids } }).json();
  }
}
